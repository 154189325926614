.formMain {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
}

.formMain input{
    margin-top: 10px;
    width: 210px !important;
} 

.buttonOut{
    display: flex;
    flex-direction: row;
}

.textSize{
    font-size: 21px !important;
}

.greyColor{
    background:grey !important
}

.btn-group .jumpbtns {
    border: 2px solid transparent;
}

.checkAvailability{
    border: none;
    margin-left: 25px;
    text-align: center;
    display: inline-block;
    padding: 4px 20px;
    background: #1c6bcf;
    color: white;
    margin-top: 10px;
    margin-bottom: 15px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    font-size: 18px;
    font-weight: 100;
}

.textSize{
    font-size: 18px;
}
.pack-list-item {
    width: 100%;
    padding: 0.25rem; 
}
.pack-card-body {
    padding: 0.25rem;
    font-weight: 400;
}