#overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    cursor: pointer;
  }



#card-button{
    display: none;
    
}

#payment-form {
    display: none;
    width:409px;
    background: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1051!important;
    border: 1px solid dimgray; 
}

#square-em-payment-form{
    display: none;
    width:409px;
    background: white;
    border: 1px solid dimgray; 
    margin-top: 30px;
}

#payment-form form{
    flex-direction: column;
}

#apple-pay-button {
  height: 48px;
  width: 100%;
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain;
  -apple-pay-button-style: black;
}


/* form{
    flex-direction: column;
} */

.sq-card-wrapper .sq-card-message{
    display: none !important;;
}

.btn-square{
    justify-content: center;
    display: flex;
    align-items: center;
    min-width: 60px;
    color: black;
    /* background: darkred; */
    height: 37px;
    /* border: 5px solid white!important; */
    
}

.sq-card-wrapper .sq-card-iframe-container {
    border-radius: 0px !important;
}



.close-button-div{
    height:45px;
    /* border: 1px solid dodgerblue!important; */
    border-bottom: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #007bff;;
    color: white;
}

.close-button-div p{
    width: 200px;
    height: 10px;
    padding-left: 10px;
    font-weight: bold;
}

.close-button-div div{
    font-weight: bolder;
    font-size: 34px;
    margin-right: 8px;
    margin-bottom: 7px;
    width: 20px;
}

.button-div{
    background: #007bff;;
    align-items: center;
    justify-content: center;
    height: 47px;
    display: flex;
    width: 100%;
}

@media only screen and (max-width : 599px) {
    #square-em-payment-form {
        width:380px;
    }
} 

@media only screen and (max-width : 499px) {
    #payment-form {
        width: 300px;
    }
}

@media only screen and (max-width : 465px) {
    #square-em-payment-form {
        width:340px;
    }

    .giftcard-square-adjustment{
        width: 300px!important;
    }
}