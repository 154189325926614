.change_res_primary{
    background-color:rgba(235, 244, 247, 0.72);
    overflow-x: hidden;
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 50px;
}

.logo{
    padding-bottom: 10px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.heading{
    font-weight: 400;
    letter-spacing: 5;
    background-color: #00419d;
    color: white;
    width: 100%;
    padding-top: 30;
    padding-bottom: 30;
    padding: 60px;
}

.change_res_sec{
    display: flex;
    flex-direction: column;
}

.reservation_details{
    /* padding: 20px; */
    /* width: 50%; */
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
}

.reservation_details_sec{
    width:350px;
    background-color: #f0f0f0;
    height: 300px;
    /* padding-top: 20px; */
    overflow-y: scroll;
}

.reservation_details_sec_row{
    display: flex;
    height: 45px;
    padding-left: 20px;
    background: white;
    align-items: center;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
}

.reservation_details_sec_row div:nth-child(1){
    font-weight: 700;
}

.reservation_details_sec_row div{
    width: 50%;
}

.change_date{
    display: flex;
    margin-top: 20px;
    justify-content: space-evenly;
}

.calendar{
    /* width: 50%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.reservation_details p{
    font-size: large;
    font-weight: 800;
}

.calendar p{
    font-size: large;
    font-weight: 800;
}

.slots_timing{
    text-align: center;
    margin-top: 50px;
}

.change_date_btn{
    margin-top: 40px;
    background-color: #00419d;
    color: white;
    border-radius: 25px;
    width: 200px;
    height: 40px;
    border: 1px solid white;
}

.calendar .react-calendar{
    border: none;
    margin-left: auto;
}

.date_title{
    margin-left:-15px !important;
    font-weight: 500;
}

@media only screen and (max-width:999px){
    .change_date{
        flex-direction: column;
    }

    .calendar{
        margin-top: 30px;
    }
}

@media only screen and (max-width:480px){
    .timing_div .appFont{
        max-width: 65%;
    }

    .timing_div .appFont i{
        margin-top: 3px;
    }
}
