.discountInputContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  flex-direction: column;
}
.discountInput {
  position: relative;
  margin: 10px;
  width: 300px;
}

.discountInput > input {
  padding: 0px 10px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #1c6bcf;
  width: 100%;
  max-width: 400px;
}

.discountInput > img {
  position: absolute;
  width: 20px;
  padding: 1px;
  margin: 0 10px;
}

.discountInputError {
  color: red;
  font-size: 12px;
}