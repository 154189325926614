.tg_main{
    width: 95%;
    margin-left: 10px;
    height: 400px;
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.tg_modal{
    position: absolute;
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid lightgray;
    width:50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 25px;
}

.tg_header{
    width: 100%;
}

.tg_header img{
    width: 100%;
}

.user_info_card{
    margin: 15px 0 20px 0;
}

.submit_btn{
    background: rgb(1, 62, 139);
    height: 40px;
    color: white;
    font-weight: 500;
    font-size: 19px;
    border-radius: 5px;
    border: 1px solid dimgray;
}

@media only screen and (max-width: 524px){
    .tg_modal{
        width:84%;
    }
}