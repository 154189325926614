
.render_conditions_main{
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;

}

.render_condition{
    width: 100%;
    /* height: 30px; */
    display: flex;
    /*align-items: center;
    /* overflow: scroll; */
}

.config_terms_conditions{
    max-height: 300px;
    overflow-y: scroll;
}

.em-terms-conditions-main{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.em-terms-conditions {
    width: 80%;
    background: #e7f2f6;
    border-radius: 5px;
    padding: 20px;
    font-size: 0.9rem;
}

.render_conditions_em_main  {
    width: 80%!important;
    background: #e7f2f6;
    border-radius: 5px;
    padding: 20px;
    height: auto;
    font-size: 0.9rem;
}

.accept_terms_conditions_btn_em {
    margin-top: 20px;
}

.btn-primary-em {
    color: #fff;
    background-color: #1c6bcf;
    border-color: #1c6bcf;
}

.terrms_conditions_primary{
    min-width: 55%;
}

.terrms_conditions_primary .modal-content{
    width: 90%!important;
}

.render_condition span:nth-of-type(1){
    width: 4%;
    margin-right: 2px;
}

.render_condition span:nth-of-type(2){
    width: 4%;
}

.render_condition .condition-description{
    width: 90%!important;
}
@media screen and  (width<450px){
    .render_condition span:nth-of-type(1){
        width: 5%;
        margin-right: 5px;
    }
}
@media screen and  (width<450px){
    .condition-description{
      margin-left: 2px;
    } 

    .header_font_size h5{
        font-size: 0.9rem!important;
    }
}
@media screen and  (width<500px){
    .condition-description{
      margin-left: 2px;
    } 
}
@media screen and  (width>1360px){
    .condition-description{
      margin-right: 5%;
    } 
}
@media screen and  (width>1560px){
    .condition-description{
      margin-right: 5%;
    } 
}
 @media screen and  (width>1850px){
    .condition-description{
      margin-right: 5%;
    } 
} 

@media screen and  (width<999px){
    .render_condition span:nth-of-type(2){
        width: 6%;
    }
    
    .render_condition .condition-description{
        width: 88% !important;
    }
}

@media screen and  (width < 1400px){
    .terrms_conditions_primary{
        min-width: 65%;
    }
}

@media screen and (width < 769px) {
    .render_conditions_em_main, .em-terms-conditions {
        width: 90%!important;
        font-size: 0.8rem;
    }
}
