.customHeading{
    text-align: center;
    display: inline-block;
    padding: 10px;
    background: #1c6bcf;
    color: white;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    font-size: 18px;
    font-weight: 100;
}

.marginBwAccordion{
    margin-bottom: 3px;
    position: relative;
}

.btn-group .jumpbtns:focus {
    border: 2px solid goldenrod !important;
    box-shadow : 0 0 1px rgba(0, 0, 255, 0.5);
    background-color: #007bff;
    color: white;
}

.calendar-event-active {
    border: 2px solid blue !important;
    box-shadow : 0 0 1px rgba(0, 0, 255, 0.5);
    background-color: #007bff;
    color: white;
}

.videoAdvertisement{
    position: absolute;
    right: 10px;
    color:white;
    font-weight: 300;
    top:8px;
    animation: blinker 1s linear infinite;
}

  @keyframes blinker {
    50% {
      opacity: 0.2;
    }
  }

.invoiceFormLabel{
    margin-bottom: 0px;
    font-size: 12px;
    color:rgb(8, 39, 100);
    letter-spacing: 1px;
    margin-top: 20px;
}
.invoiceFrom{
    background: transparent;
    border:0px;
    border-bottom: 1px solid #1c6bcf;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    padding: 0px;

}
.ticketContainer{
    margin: auto;
    padding: 40px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.customLabel{
    margin-bottom: 0px;
    font-size: 12px;
    color: #013e8b;
}
.customInputField{
    border: 0px;
    border-bottom: 1px solid #1c6bcf;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    color: #1c6bcf !important;
}
.checkoutButton:disabled{
    background-color:rgb(1, 62, 139);
}
.cardOverRide{
    max-width:500px;
    margin:auto;
    margin-top:50px;
    background:#e7f2f6;
    display: block;
    
}
.cardOverRide .header{
    font-size: 1.3rem;
    font-weight: 600;
    margin: 0;
}
.cardOverRide table{
    width: 75%;
    margin: auto;
}
.cardOverRide .card-header{
    text-align: center;
    color:rgb(8, 39, 100);
    background: transparent;
    border-bottom: 0px;
    letter-spacing: 1px;
}
.cardOverRide .table td{
    font-weight: 600;
    color: rgb(8, 39, 100);
}
.cardOverRide .table th{
    border-top:0px;
    font-size: 14px;
    font-weight: normal;
    color:rgb(8, 39, 100);
}

.google_apple_button{
    display: flex;
    justify-content: center;
    align-items: center;
}

.google_apple_button_groupon{
    margin-top: 10px!important;
}

.groupon_pay_btn{
    display: block;
    font-size: 12px !important;
    padding: 10px;
    margin-top: 0px!important;
    margin-bottom: 0px!important;
    background:rgb(1, 62, 139);
    height: 40px;
    margin: 0px !important;
    width: 242px;
}

.pay_btn{
    display: block;
    font-size: 14px!important;
    /* padding: 10px; */
    margin-top: 0px!important;
    margin-bottom: 0px!important;
    background: rgb(1, 62, 139);
    height: 40px;
    margin: 0px !important;
    width: 242px;
}

/* .gpay-card-info-container.black{
    background:rgb(1, 62, 139) !important;
    border-color: #007bff !important;
    border: 1px solid;
}

.gpay-card-info-container.black:hover{
    color: #fff;
    background-color: #0069d9!important;
    border-color: #0062cc!important;
    
} */

.google_apple_styling {
    background-color: black !important;
    border: none !important;
}

.apple_button_icon{
    margin-left: 5px;
    margin-right: 1px;
}

.mr-30{
    margin-right: 30px !important;
}
.margin_right{
    margin-right: 20px !important;
}
.checkoutButton{
    margin: auto;
    display: block;
    font-size: 22px;
    /* padding: 10px; */
    margin-top: 50px;
    margin-bottom: 10px;
    background:rgb(1, 62, 139);
}
.ticketHeading{
    font-weight: normal;
    font-size: 25px;
    color: #013e8b;

}

.react-calendar__month-view__days__day--weekend {
    color: #000!important;
}

.lightButtonBorder{
    /* border: 1px solid #c1ccd7; */
    box-shadow: #c1ccd7 0px 0px 34px 2px;
    -webkit-box-shadow:#c1ccd7 0px 0px 34px 2px;
    -moz-box-shadow:#c1ccd7 0px 0px 34px 2px;
}
.lightButtonBorder.active{
    /* border: 1px solid #c1ccd7; */
    box-shadow: #013e8b 0px 0px 34px 2px;
    -webkit-box-shadow:#013e8b 0px 0px 34px 2px;
    -moz-box-shadow:#013e8b 0px 0px 34px 2px;
}
.timeSelect{
    letter-spacing: 1px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.roundFix{
    padding-left:0.6rem !important;
    padding-right:0.6rem !important;
    color:#013e8b;
    background:rgb(198, 222, 230);
}
button.active.timeSelect{
    background:#013e8b;
}
.active.timeSelect.active>.roundFix{
    background:#022b5f;
    color:white;
}
.fontWhite{
    color:white;
}
.fontNoColor{
    font-size: 18px;
}
.appFont{
    font-size: 18px;
    color:#013e8b;
    margin-top: 15px;
}
.timeSelect.appFont.active{
    color:white;
}

hr{
    margin-top:0px !important;
    border-top:1px solid rgba(0, 0, 0, 0.62);
}
.containerPadding{
    padding-bottom: 0px !important;
    padding-left: 50px !important;
    padding-right:50px !important;
}
.react-calendar, .react-calendar *, .react-calendar *:before, .react-calendar *:after{
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border: 0px;
}
.react-calendar button{
    border-radius:0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
}
.react-calendar{
    width:100%;
    max-width: 400px;
    line-height: 1.525em;
    margin: auto;
    margin-left: 15%;
}
.error-validation{
   font-size:12px;
   margin-bottom:2px
}

.ticketPrice {
    margin-top: -14%;
    position: absolute;
    left: 14%;
    color: red;
    font-size: 20px;
}

.perticketPrice {
  /* margin-top: 0; */
  position: absolute;
  left: 60%;
  color: rgb(31, 63, 220);
  /* font-size: 20px; */
  font-weight: 900;
  top: 32%;
  width: 35%;
}

.gift-option{
    margin-left: 50px;
    margin-top: 2%;
}

.jump-price-label{
  position: relative;
  top: 41px;
  right: 42px;
  color: rgb(31, 63, 220);
  font-size: 20px;
  font-weight: 600;
}
.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
  outline: none;
}

.accordion__panel p{
    font-size: 19px;
    margin-left: 7%;
}

.collapse .card-body{
    font-size: 17px;
}

.groupon_reserve-btn {
    margin-top: 0px;
    height: 45px;
    font-size: 18px;
    width:242px;
}

.tandumJumpmg{
    margin-top: 25px;
}
@media (min-width:380px) and (max-width:480px) {
    .text-heading {
        font-size: 15px;
        width: 100%;
        padding: 25px 0px!important;
    }
    .dateSelectector {
        margin-top: 260px;
    }
    span.customHeading {
        width: 100%;
        font-size: 16px;
    }
    .fontNoColor {
        font-size: 13px;
    }
    .appFont {
        font-size: 10px;
        color: #013e8b;
        width: 100%;
        margin: 8px 0;
        padding: 5px;
        position: relative;
        padding-left: 35px;
    }
    .containerPadding {
        padding-bottom: 0px !important;
        padding-left: 30px !important;
        padding-right: 30px !important;
        margin-top: -18px;
    }
    .react-calendar__navigation button {
        min-width: 44px;
        background: none;
        font-size: 14px;
    }
    .react-calendar button {
        font-size: 13px;
    }
    .appFont i {
        text-align: left!important;
        left: 0;
        position: absolute;
        top:0;
    }
    .ticketHeading {
        font-weight: normal;
        font-size: 14px!important;
        color: #013e8b;
    }
    .radio-btns {
        width: 100%;
        float: left;
        position: relative;
        padding: 0 15px;
    }
    .radio-btns span {
        font-size: 13px;
    }
    .react-calendar {
        position: relative;
    }
    .ticketPrice {
        top: unset;
        position: absolute;
        right: 39px;
        color: red;
        bottom: -24px;
        font-size: 15px;
        left: 32px;
    }

    .perticketPrice {
        margin-top: 90%;
        position: absolute;
        left: 7%;
        color: rgb(31, 63, 220);
        /* font-size: 15px; */
        font-weight: 900;
        width:25%

      

    }

    .ticketContainer {
        margin: auto;
        padding: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .padding-no{
        padding-left: 0px;
        padding-right: 0px;
    }
    .checkoutButton{
        font-size: 15px;
        margin-top:20px;
    }
    /* .googlePayButton{
        margin-left: 30px;
    } */

    .media-object{
        max-width: 100% !important;
    }
}

@media (min-width:370px) and (max-width:379px) {
    .text-heading {
        font-size: 15px;
        width: 100%;
        padding: 25px 0px!important;
    }
    .dateSelectector {
        margin-top: 260px;
    }
    span.customHeading {
        width: 100%;
        font-size: 13px;
    }
    .fontNoColor {
        font-size: 13px;
    }
    .appFont {
        font-size: 10px;
        color: #013e8b;
        width: 100%;
        margin: 8px 0;
        padding: 5px;
        position: relative;
        padding-left: 35px;
    }
    .containerPadding {
        padding-bottom: 0px !important;
        padding-left: 30px !important;
        padding-right: 30px !important;
        margin-top: -18px;
    }
    .react-calendar__navigation button {
        min-width: 44px;
        background: none;
        font-size: 14px;
    }
    .react-calendar button {
        font-size: 13px;
    }
    .appFont i {
        text-align: left!important;
        left: 0;
        position: absolute;
        top:0;
    }
    .ticketHeading {
        font-weight: normal;
        font-size: 14px!important;
        color: #013e8b;
    }
    .radio-btns {
        width: 100%;
        float: left;
        position: relative;
        padding: 0 15px;
    }
    .radio-btns span {
        font-size: 13px;
    }
    .react-calendar {
        position: relative;
    }
    .ticketPrice {
        top: unset;
        position: absolute;
        right: 39px;
        color: red;
        bottom: -24px;
        font-size: 15px;
        left: 32px;
    }

    .perticketPrice {
        margin-top: 100%;
        position: absolute;
        left: 7%;
        color: rgb(31, 63, 220);
        /* font-size: 15px; */
        font-weight: 900;
        width:25%
    }

    .ticketContainer {
        margin: auto;
        padding: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .padding-no{
        padding-left: 0px;
        padding-right: 0px;
    }
    .checkoutButton{
        font-size: 15px;
        margin-top:20px;
    }
    /* .googlePayButton{
        margin-left: 40px;
    } */
    .media-object{
        max-width: 100% !important;
    }
}

@media (min-width:320px) and (max-width:369px) {
    .text-heading {
        font-size: 15px;
        width: 100%;
        padding: 25px 0px!important;
    }
    .dateSelectector {
        margin-top: 260px;
    }
    span.customHeading {
        width: 100%;
        font-size: 13px;
    }
    .fontNoColor {
        font-size: 13px;
    }
    .appFont {
        font-size: 10px;
        color: #013e8b;
        width: 100%;
        margin: 8px 0;
        padding: 5px;
        position: relative;
        padding-left: 35px;
    }
    .containerPadding {
        padding-bottom: 0px !important;
        padding-left: 30px !important;
        padding-right: 30px !important;
        margin-top: -18px;
    }
    .react-calendar__navigation button {
        min-width: 44px;
        background: none;
        font-size: 14px;
    }
    .react-calendar button {
        font-size: 13px;
    }
    .appFont i {
        text-align: left!important;
        left: 0;
        position: absolute;
        top:0;
    }
    .ticketHeading {
        font-weight: normal;
        font-size: 14px!important;
        color: #013e8b;
    }
    .radio-btns {
        width: 100%;
        float: left;
        position: relative;
        padding: 0 15px;
    }
    .radio-btns span {
        font-size: 13px;
    }
    .react-calendar {
        position: relative;
    }
    .ticketPrice {
        top: unset;
        position: absolute;
        right: 39px;
        color: red;
        bottom: -24px;
        font-size: 15px;
        left: 32px;
    }

    .perticketPrice {
        margin-top: 117%;
        position: absolute;
        left: 7%;
        color: rgb(31, 63, 220);
        /* font-size: 15px; */
        font-weight: 900;
        width:25%
    }

    .ticketContainer {
        margin: auto;
        padding: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .padding-no{
        padding-left: 0px;
        padding-right: 0px;
    }
    .checkoutButton{
        font-size: 15px;
        margin-top:20px;
    }
    .googlePayButton{
        margin-left: 40px;
    }
    .media-object{
        max-width: 100% !important;
    }
}

.terms_conditions{
    color: #000;
    font-size: 13px;
    margin-left: 5px;
}
.ModalCloseButton{
    content: "X";
    color: white;
    border-radius: 50%;
    background: dodgerblue;
    padding: 5px 10px;
    font-size: 14px;
    right: 10px;
    position: absolute;
    cursor: pointer;
}
.ModalCloseButton:hover{
    background:dodgerblue;
    font-size: 15px;
    padding: 5px 11px;
}
.terms_conditions__button{
    width: 200px;
    height: 39px;
    color: #fff;
    background: #5274a6;
    outline: none;
    border-radius: 5px;

}
.terms_conditions__button:hover{
    outline: none;
    background: #1c6bcf
}
.terms_conditions__button:active{
    outline: none;
}
.terms_conditions__button:focus{
    outline: none;
}

.height_adjusment{
    height:36px;
}
.height_adjusment_visa{
    height: 34px;
}
.videoContainer{
    width: 100%;
    height: 200px;
}
/* 
@media (max-width: 1200px){
    .perticketPrice{
        position: static;
        margin-top: 100px;
        width: 70%;
        align-self: center;
      }
} */

@media (max-width: 1200px){

.react-calendar{
    margin-left: 0;
    margin: auto;
}
.videoContainer{
    width: 100%;
    height: 300px;
}
  .perticketPrice{
    position: static;
    margin-top: 100px;
    width: 100%;
    align-self: center;
  }
  .alert-info{
    position: absolute;
    bottom: 310px;
    width: calc(100% - 60px);
    left: 30px;
  }
  .perticketPrice.active {
    margin-top: 50px;
  }
}
@media (max-width: 767px){
  .media img{
    width: 110px;
  }
  .media-body button{
    font-size: 15px;
  }
}
@media (max-width: 480px){
    .videoContainer{
        height: 170px;
    }
  .alert-info {
    bottom: 234px;
    font-size: 15px;
  }
  .perticketPrice{
    position: static;
    margin-top: 80px;
    width:100%
  }
  .perticketPrice.active {
    margin-top: 25px;
  }
  .dateSelectector {
    margin-top: 0px;
  }
  .media{
    flex-direction: column-reverse;
  }
  .media-body, .media-top, .media-top img{
    width: 100%;
  }
  .fontNoColor {
    margin: 15px 0px 10px
  }
  .appFont i{
    top: calc(50% - 16px);
  }
  .height_adjusment{
      height:27px;
  }
  .height_adjusment_visa{
      height: 23px;
  }
}

input,
textarea {
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  margin: 0;
  outline: none;
  padding: 10px;
}

input[type="button"] {
  -webkit-appearance: button;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input-group {
  clear: both;
  margin: 15px 0;
  position: relative;
}

.input-group input[type='button'] {
  background-color: #eeeeee;
  min-width: 38px;
  width: auto;
  transition: all 300ms ease;
}

.input-group .button-minus,
.input-group .button-plus {
  font-weight: bold;
  height: 38px;
  padding: 0;
  width: 38px;
  position: relative;
}

.input-group .quantity-field {
  position: relative;
  height: 38px;
  left: -6px;
  text-align: center;
  width: 62px;
  display: inline-block;
  font-size: 13px;
  margin: 0 0 5px;
  resize: vertical;
}

.input-group .seat-error {
    box-shadow: 0px 0px 10px rgb(168, 29, 29);
}

.button-plus {
  left: -13px;
}

input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: none;
}

.btn-group, .btn-group-vertical {
    margin-top: 2% !important;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn {
    margin-right: 1% !important;
}
.sweet-loading {
    position: absolute;
    top: 75%;
    margin-left: 20px;
    z-index: 1052 !important;;
    margin-left: 26%;
  }