.right-side-info{
    color: white;
    background-color: #007bff;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.highlight {
    color:red;
 }
.btn-style{
    width:25%;
    margin-top: 15px;
}
@media screen and (width<425px){
    .btn-style{
        font-size:15px;
    }
}
@media screen and (width<366px){
    .btn-style{
        font-size:12px;
    }
}

@media screen and  (width>1200px){
    .tandem-date-show{
        width: 60%;
    }
}
