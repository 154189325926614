#loader_overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(58,58,58,0.5);
    z-index: 5;
    cursor: pointer;
}


.loader_main{
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    position: absolute;
}